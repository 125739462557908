$green-color: #67b50f;
$white-color: #ffffff;
$blue-color: #000059;
$blue-footer: #000059;
$black-color: #0e101b;
$font-size: 16px;
$transition: 0.5s;
$font-family: "Inter", sans-serif;
$optional-font-family: "Montserrat", sans-serif;


body {
  padding: 0;
  margin: 0;
  font: {
      family: $font-family;
      size: $font-size;
  }
}

.meanclose{
  color: #000059 !important;
  font-size: 20px !important;
  font-weight: bold !important;
  margin-top: 25px !important;
}

.btn-green-ts {
  background-color: $green-color !important;
  border: $green-color !important;
  color: $white-color !important;
  padding: 9px 20px !important;
  font-size: 12px !important;
  &::before {
    content: "";
    background: $green-color;
  }

  &::after {
    background: $green-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }

  &.disabled,
  &:disabled {
    color: $black-color;
    background-color: $green-color;
  }

  &:hover,
  &:focus {
    color: $white-color !important;
    background-color: $green-color !important;
  }
  &:hover {
    background-color: $green-color !important;
  }
}

.ts-btn-green-content {
  background-color: $green-color !important;
  border: $green-color !important;
  color: $white-color !important;
  padding: 12px 30px !important;
  font-size: 12px !important;
  &::before {
    content: "";
    background: $green-color;
  }

  &::after {
    background: $green-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }

  &.disabled,
  &:disabled {
    color: $black-color;
    background-color: $green-color;
  }

  &:hover,
  &:focus {
    color: $white-color !important;
    background-color: $green-color !important;
  }
  &:hover {
    background-color: $green-color !important;
  }
}

h2 {
  font-size: 36px;
}

.title-class{
  color: $white-color;
  font-weight: 600;
}

.ts-footer-area {
  background-color: $blue-footer;
}

.ts-cta-content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.cta-content a.phone-number-link-class{
  font-size: 20px;}

.single-features-box .icon {
  color: $blue-color;


  &:hover {
    color: $white-color !important;

    .back-icon {
      color: $white-color;
      opacity: 0.02;
    }
  }
}

.faq-accordion .accordion .accordion-item .accordion-title i.ts-bg-icon {
  background-color: $green-color;
}

.vimi-nav .navbar .navbar-nav .nav-item:hover a.ts-color-navbar {
  color: $green-color;
}
.vimi-nav .navbar .navbar-nav .nav-item a.ts-color-navbar {
  color: $blue-color;
}

.vimi-nav .navbar .navbar-nav .nav-item .dropdown-menu.dropdown-menu-ts {
  background-color: $white-color;
}
/*
.faq-accordion .accordion .accordion-item .accordion-title i {
  background-color: $green-color;
}
 */
.single-pricing-table .pricing-header .icon .ts-bg-icon {
  background-color: $green-color;
}
.single-pricing-table:hover .icon .ts-bg-icon {
  background-color: $green-color;
}

.ts-bg-green {
  background-color: $green-color;
}

.ts-container-copyright {
  display: flex;
  justify-content: space-between;
}

.ts-bg-green:hover {
  background-color: $green-color;
}

/* .vimi-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  background-color: $white-color;
} */

.cta-content a {
  color: $green-color;
}

.single-blog-post .blog-post-content ul li i {
  color: $green-color;
}

a:hover {
  color: $green-color;
}

.single-blog-post .blog-post-content ul li a:hover {
  color: $green-color;
}

.single-pricing-table .price span:nth-child(1) {
  color: $white-color;
  font-size: 12px;
}

.ts-container {
  max-width: 1550px;
}

.btn-blue-ts {
  background-color: $blue-color !important;
  padding: 9px 20px !important;
  font-size: 12px !important;
  color: $white-color;
}

.ts-btn-blue-card {
  background-color: $blue-color !important;
  padding: 15px 28px !important;
  font-size: 14px !important;
  color: $white-color;
}

.ts-btn-green-card {
  background-color: #67b50f !important;
  border: #67b50f !important;
  color: #ffffff !important;
  padding: 15px 28px !important;
  font-size: 14px !important;
}

.navbar-area-ts {
  background-color: $white-color;

  &.is-sticky {
    background-color: $white-color !important;
  }
}

.vimi-nav .navbar .navbar-nav .nav-item .dropdown-menu-ts li.active a {
  color: $green-color;
}

.color-list {
  color: white;
  list-style-type: none;
}

.mi-clase-lista{
  padding-left: 8px;
}

.single-footer-widget {
  margin-bottom: 30px;

  h3 {
      color: $white-color;
      margin-bottom: 20px;
      font: {
          size: 24px;
          weight: 700;
      }
  }
  .logo {
      a {
          display: inline-block;
      }
      p {
          color: #d7d7d7;
          font-size: 14px;
          margin-top: 18px;
      }
  }
  .newsletter-form {
      margin-top: 15px;
      position: relative;

      .newsletter-input {
          display: block;
          width: 100%;
          height: 50px;
          border: 1px solid #908482;
          background-color: transparent;
          border-radius: 30px;
          color: $white-color;
          padding-left: 15px;
          font-size: 14px;
          outline: 0;

          &::placeholder {
              color: #d7d7d7;
          }
      }
      button {
          position: absolute;
          outline: 0;
          right: 0;
          top: 0;
          height: 50px;
          width: 55px;
         /*  background-color: $main-color; */
          color: $black-color;
          border: none;
          transition: $transition;

          i {
              &::before {
                  font-size: 20px;
              }
          }
          &:hover {
              color: $white-color;
             /*  background-color: $optional-color; */
          }
      }
      .validation-danger {
          color: red;
      }
      .validation-success {
          color: $white-color;
      }
  }
  .services-widget-list {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
          margin-bottom: 9px;

          a {
              color: #d7d7d7;

              &:hover {
                  /* color: $optional-color; */
                  padding-left: 5px;
              }
          }
      }
  }
  .links-widget-list {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
          margin-bottom: 9px;

          a {
              color: #d7d7d7;

              &:hover {
                  /* color: $optional-color; */
                  padding-left: 5px;
              }
          }
      }
  }
  .account-widget-list {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
          margin-bottom: 9px;

          a {
              color: #d7d7d7;

              &:hover {
                 /*  color: $optional-color; */
                  padding-left: 5px;
              }
          }
      }
  }
  .widget-contact-info {
      p {
          color: #d7d7d7;

          a {
             /*  color: $main-color; */

              &:hover {
                 /*  color: $optional-color; */
              }
          }
          span {
              display: block;
          }
      }
  }
  .footer-contact-info {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
          margin-bottom: 9px;
          color: #d7d7d7;

          a {
              color: #d7d7d7;

              &:hover {
                 /*  color: $optional-color; */
              }
              i {
            /*       color: $optional-color; */
              }
          }
      }
  }
}

.ts-section-title {
  h2 {
    color: $blue-color !important;
  }
  margin: {
    bottom: 30px;
    left: auto;
    right: auto;
}
}

.ts-bg-not-white {
  background-color: #f5f5f5;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;

  .overlay-text {
    color: white;
  }

  h2 {
    font-size: 36px;
  }
}

.single-pricing-table.ts-single-card {
  /*    background-color: $optional-color; */
  padding: 30px;
  border-radius: 10px;
  background-image: url("https://api.cms.dev.tecnosystem.net.ve/api/media/798d26fc-3a60-4f20-ac24-4721d662150e") !important;
  .pricing-header {
    .icon.ts-icon {
      background-color: $green-color;
      width: 65px;
      height: 65px;
    }
  }
  .pricing-features-list {
    position: relative;
    z-index: 1;
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    li {
      color: $white-color;
      margin-bottom: 10px;

      i {
        /*  background-color: #fb898d; */
        display: inline-block;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        text-align: center;
        line-height: 22px;
        transition: $transition;

        &::before {
          font-size: 9px;
        }
      }
    }
  }
  .ts-single-card  .price {
    position: relative;
    z-index: 1;
    margin-top: 20px;
    color: $white-color;

    font: {
      size: 30px;
      weight: 700;
    }
    span {
      &:nth-child(1) {
        display: block;
        color: $blue-color;
        margin-bottom: -5px;
        position: relative;
        left: 3px;
        font: {
          size: $font-size;
          weight: normal;
        }
      }
      &:nth-child(2) {
        position: relative;
        right: -4px;
        bottom: 1px;
        font: {
          size: 14px;
          weight: normal;
        }
      }
      &:nth-child(3) {
        position: relative;
        left: -5px;
        font: {
          size: $font-size;
          weight: normal;
        }
      }
    }
  }
  .view-plans-btn {
    position: relative;
    z-index: 1;
    margin-top: 25px;
    display: inline-block;
    padding: 11px 30px;
    color: $white-color;
    border-radius: 30px;
    border: 2px solid $green-color;
    font: {
      size: 15px;
      weight: 700;
      family: $optional-font-family;
    }
    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: $white-color !important;
    }
  }
  &::before {
    z-index: -1;
    background-image: url("https://api.cms.dev.tecnosystem.net.ve/api/media/798d26fc-3a60-4f20-ac24-4721d662150e");

    background-repeat: no-repeat;
  }
  &::after {
    background-image: url("https://api.cms.dev.tecnosystem.net.ve/api/media/798d26fc-3a60-4f20-ac24-4721d662150e");
  }


}
.ts-home-image {
  background-image: url("https://api.cms.dev.tecnosystem.net.ve/api/media/f55378b6-1f50-4d96-b255-8caa6995a97a");
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
 filter: brightness(0.8);

}

.ts-title-home {
  color: white;
  font-size: 54px;
  margin-top: 30px;
  font-weight: bold;
}

@media only screen and (max-width: 1023px) {
   .ts-title-home {
    color: white;
    font-size: 40px;
    margin-top: 30px;
    font-weight: bold;
  }

  .image-class {
    width: 20%;
  }
  .overlay {
    h2 {
      font-size: 25px;
    }
  }
}



@media only screen and (max-width: 765px) {
  .ts-home-image {
    background-image: url("https://api.cms.dev.tecnosystem.net.ve/api/media/a4f6493c-2142-4f32-8a82-7207d88363dc");
  }

  .ts-title-home {
    color: white;
    font-size: 20px;
    margin-top: 30px;
    font-weight: bold;
  }

  .image-class {
    width: 18%;
  }
  .overlay {
    h2 {
      font-size: 16px;
    }
  }
  .ts-container-copyright {
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 24px;
  }

  .single-footer-widget {
    margin-bottom: 0px;

    h3 {
        color: $white-color;
        margin-bottom: 20px;
        font: {
            size: 24px;
            weight: 700;
        }
    }
    .logo {
        a {
            display: inline-block;
        }
        p {
            color: #d7d7d7;
            font-size: 14px;
            margin-top: 18px;
        }
    }
    .newsletter-form {
        margin-top: 15px;
        position: relative;

        .newsletter-input {
            display: block;
            width: 100%;
            height: 50px;
            border: 1px solid #908482;
            background-color: transparent;
            border-radius: 30px;
            color: $white-color;
            padding-left: 15px;
            font-size: 14px;
            outline: 0;

            &::placeholder {
                color: #d7d7d7;
            }
        }
        button {
            position: absolute;
            outline: 0;
            right: 0;
            top: 0;
            height: 50px;
            width: 55px;
            /* background-color: $main-color; */
            color: $black-color;
            border: none;
            transition: $transition;

            i {
                &::before {
                    font-size: 20px;
                }
            }
            &:hover {
                color: $white-color;
               /*  background-color: $optional-color; */
            }
        }
        .validation-danger {
            color: red;
        }
        .validation-success {
            color: $white-color;
        }
    }
    .services-widget-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 9px;

            a {
                color: #d7d7d7;

                &:hover {
                    /* color: $optional-color; */
                    padding-left: 5px;
                }
            }
        }
    }
    .links-widget-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 9px;

            a {
                color: #d7d7d7;

                &:hover {
               /*      color: $optional-color; */
                    padding-left: 5px;
                }
            }
        }
    }
    .account-widget-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 9px;

            a {
                color: #d7d7d7;

                &:hover {
                   /*  color: $optional-color; */
                    padding-left: 5px;
                }
            }
        }
    }
    .widget-contact-info {
        p {
            color: #d7d7d7;

            a {
              /*   color: $main-color; */

                &:hover {
                /*     color: $optional-color; */
                }
            }
            span {
                display: block;
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 9px;
            color: #d7d7d7;

            a {
                color: #d7d7d7;

                &:hover {
                 /*    color: $optional-color; */
                }
                i {
                  /*   color: $optional-color; */
                }
            }
        }
    }
}

.ts-copyright-area {
   margin-top: 0px;
  }

.mi-clase-lista{
  text-align: left;
  margin-left: 10px;
}
}

.text-p{
  font-size: 25px !important;
}
