/*
Flaticon icon font: Flaticon
*/

@font-face {
    font-family: "Flaticon";
    src: url("../../assets/fonts/Flaticon.eot");
    src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Flaticon.woff2") format("woff2"),
    url("../../assets/fonts/Flaticon.woff") format("woff"),
    url("../../assets/fonts/Flaticon.ttf") format("truetype"),
    url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    }
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-size: 15px;
    font-style: normal;
}

.flaticon-wifi-signal:before { content: "\f100"; }
.flaticon-multimedia:before { content: "\f101"; }
.flaticon-wifi:before { content: "\f102"; }
.flaticon-speedometer:before { content: "\f103"; }
.flaticon-speedometer-1:before { content: "\f104"; }
.flaticon-download-to-storage-drive:before { content: "\f105"; }
.flaticon-support:before { content: "\f106"; }
.flaticon-arrow-pointing-to-right:before { content: "\f107"; }
.flaticon-care-about-environment:before { content: "\f108"; }
.flaticon-technical-support:before { content: "\f109"; }
.flaticon-router:before { content: "\f10a"; }
.flaticon-wifi-1:before { content: "\f10b"; }
.flaticon-wifi-signal-tower:before { content: "\f10c"; }
.flaticon-online-shop:before { content: "\f10d"; }
.flaticon-key:before { content: "\f10e"; }
.flaticon-shield:before { content: "\f10f"; }
.flaticon-resume:before { content: "\f110"; }
.flaticon-check-mark:before { content: "\f111"; }
.flaticon-help:before { content: "\f112"; }
.flaticon-add:before { content: "\f113"; }
.flaticon-remove:before { content: "\f114"; }
.flaticon-facebook:before { content: "\f115"; }
.flaticon-facebook-message:before { content: "\f116"; }
.flaticon-download-arrow:before { content: "\f117"; }
.flaticon-paper-plane:before { content: "\f118"; }
.flaticon-quote-left:before { content: "\f119"; }
.flaticon-place:before { content: "\f11a"; }
.flaticon-address-book:before { content: "\f11b"; }
.flaticon-signal:before { content: "\f11c"; }
.flaticon-pdf:before { content: "\f11d"; }
.flaticon-txt:before { content: "\f11e"; }